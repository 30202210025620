import React from "react";

import { formatFigureText } from "../../../utils/functions";

import "./index.scss";

const Figure = ({ text, image }) => {
  return (
    <div className="grid gap-8 additional-figure">
      <img src={image.file.url} alt={image.fileName} className="mx-auto" />
      <div dangerouslySetInnerHTML={{ __html: formatFigureText(text) }} />
    </div>
  );
};

const AdditionalFigures = ({ title, figure1, figure2, figure3 }) => {
  return (
    <div className="px-4 lg:px-16 py-24 lg:max-w-78 mx-auto">
      <div className="grid gap-12">
        <h3 className="text-secondary justify-self-center text-center uppercase">{title}</h3>
        <div className="grid lg:grid-cols-2 gap-8">
          <Figure text={figure1.text} image={figure1.image} />
          <Figure text={figure2.text} image={figure2.image} />
        </div>
        <Figure text={figure3.text} image={figure3.image} />
      </div>
    </div>
  );
};

export default AdditionalFigures;
